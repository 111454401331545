// eslint-disable-next-line no-unused-vars
import { PluginFunction } from 'vue'
import axios from 'axios'
import lodash from 'lodash'
const wasm = import('@/wasm/pkg')

export default {
  /** @type {PluginFunction<never>} */
  install: function (Vue, options) {
    let exposedHttp
    Object.defineProperty(Vue.prototype, '$http', {
      get: function () {
        if (!exposedHttp) {
          exposedHttp = http
          this.$store = options.store
        }

        return exposedHttp.bind(this)
      }
    })
  }
}

const http = function () {
  const self = this

  // axios.defaults.baseURL = 'http://localhost:59821/api/'
  axios.defaults.baseURL = '/api'

  axios.defaults.validateStatus = function (status) {
    if (status === 401 && self.$router.currentRoute.name !== 'login') {
      window.sessionStorage.clear()

      import('@/authenticationController').then(authenticationController => {
        if (authenticationController.isExternal()) {
          self.$router.replace({ path: '/externalLoginProviderSelection' + (authenticationController.isOutsider() ? '/extern' : ''), query: { redirect: self.$router.currentRoute.path.trimStart('#') } })
        } else {
          self.$router.replace({ path: '/login', query: { redirect: self.$router.currentRoute.path.trimStart('#') } })
        }
      })
      return false
    }

    if (status === 403) {
      self.$router.push('/forbidden')
    }

    return status >= 200 && status < 300 // default
  }

  setAuthorization(window.sessionStorage.getItem('access_token'), JSON.parse(window.sessionStorage.getItem('helpDocs')) ?? '{}')

  function setAuthorization (token, helpDocs) {
    if (token) {
      window.sessionStorage.setItem('access_token', token)
      window.sessionStorage.setItem('helpDocs', JSON.stringify(helpDocs ?? {}))
      axios.defaults.headers.common.authorization = `Session ${token}`
      wasm.then(w => w.setHeader('authorization', `Session ${token}`))
    } else {
      window.sessionStorage.removeItem('access_token')
      window.sessionStorage.removeItem('helpDocs')
      delete axios.defaults.headers.common.authorization
      wasm.then(w => w.removeHeader('authorization'))
    }
  }

  return {
    get hasToken () {
      return Boolean(window.sessionStorage.getItem('access_token'))
    },
    getAuthorizationHeader () {
      return axios.defaults.headers.common.authorization
    },
    async authenticate (username, password, rememberMe, captchaToken) {
      const r = await this.post('core/authentication', { username, password, captchaToken, isApp: true }, { headers: { authorization: null }, validateStatus: status => status >= 200 && status < 300 })

      if (r.data && r.data.access_token) {
        await this.authenticateFromToken(r.data.access_token)

        if (rememberMe) {
          const sessionToken = {
            username: r.data.username,
            token: r.data.access_token,
            expiration: Date.now() + r.data.expires_in * 1000,
            canDisconnect: true
          }
          window.localStorage.setItem('authentication', JSON.stringify(sessionToken))
        }
      }
    },
    async authenticateFromLocalStorage () {
      const authentication = JSON.parse(window.localStorage.getItem('authentication'))
      if (authentication?.expiration > Date.now()) {
        const helpDocs = await this.getHelpDocsInformations(authentication.token)
        setAuthorization(authentication.token, helpDocs)
        await self.$store.dispatch('getCurrentUserSettings')
        return true
      } else {
        return false
      }
    },
    async authenticateFromToken (token) {
      if (token) {
        const helpDocs = await this.getHelpDocsInformations(token)
        setAuthorization(token, helpDocs)
        await self.$store.dispatch('getCurrentUserSettings')
        return true
      } else {
        return false
      }
    },
    createCancelToken () {
      return axios.CancelToken.source()
    },
    isCancel (error) {
      return axios.isCancel(error)
    },
    async getHelpDocsInformations (accessToken) {
      return this.get('core/v6/help-docs/access', { headers: { authorization: `Session ${accessToken}` } }).then(helpDocsReq => helpDocsReq.data).catch(() => undefined)
    },
    async logout () {
      setAuthorization()
      window.sessionStorage.clear()
      window.localStorage.removeItem('authentication')
      self.$store.dispatch('clearCurrentUser')

      const r = await this.get('core/authentication/get-logout-url')
      if (r?.data?.logout_url) {
        window.location = r?.data?.logout_url
      }
    },
    delete (url, options) {
      return axios.delete(url, options)
    },
    get (url, options) {
      return axios.get(url, options)
    },
    post (url, data, options) {
      return axios.post(url, data, options)
    },
    put (url, data, options) {
      return axios.put(url, data, options)
    },
    patch (url, data, options) {
      return axios.patch(url, data, options)
    },
    baseURL () {
      return axios.defaults.baseURL
    },
    debounced (delay) {
      return new DebouncedHttp(axios, delay)
    },
    versionHashed () {
      return new VersionHashedHttp(axios)
    }
  }
}

class VersionHashedHttp {
  constructor(axios) {
    this._axios = axios

    this._loadOpt = function (url, options) {
      const key = 'verhash:' + url
      let stored = null
      try {
        const str = window.sessionStorage.getItem(key)
        stored = str ? JSON.parse(str) : null
      } catch { }

      if (!stored?.versionHash) {
        stored = null
      } else {
        options = lodash.cloneDeep(options ?? {})

        if (!options.headers) { options.headers = {} }
        options.headers['Version-hash'] = stored.versionHash
      }

      return { key, options, stored }
    }

    this._saveOpt = function (token, resp) {
      return resp
        .then(e => {
          if (e.data?.versionHash) {
            window.sessionStorage.setItem(token.key, JSON.stringify(e.data))
          }
          return Promise.resolve(e)
        })
        .catch(e => {
          if (e.response.status === 304) {
            e.response.data = token.stored
            return Promise.resolve(e.response)
          } else {
            return Promise.reject(e)
          }
        })
    }
  }

  delete (url, options) {
    return this._axios.delete(url, options)
  }

  get (url, options) {
    const token = this._loadOpt(url, options)
    const p = this._axios.get(url, token.options)
    return this._saveOpt(token, p)
  }

  put (url, data, options) {
    return this._axios.put(url, data, options)
  }

  post (url, data, options) {
    const token = this._loadOpt(url, options)
    const p = this._axios.post(url, data, token.options)
    return this._saveOpt(token, p)
  }
}

export class DebouncedHttp {
  constructor(axios, delay = 200) {
    this._rejectHandler = undefined

    this._req = lodash.debounce(async function (resolve, reject, method, args) {
      axios[method].apply(null, args)
        .then(data => {
          // Clearing to avoid a memory leak
          this._rejectHandler = undefined
          resolve(data)
        })
        .catch(reject)
    }, delay)
  }

  delete (url, options) {
    return this.req('delete', url, options)
  }

  get (url, options) {
    return this.req('get', url, options)
  }

  put (url, data, options) {
    return this.req('put', url, data, options)
  }

  post (url, data, options) {
    return this.req('post', url, data, options)
  }

  req (method, ...args) {
    return new Promise((resolve, reject) => {
      this._rejectHandler?.('Debounced')
      this._rejectHandler = reject
      this._req(resolve, reject, method, args)
    })
  }

  versionHashed () {
    return new VersionHashedHttp(this)
  }
}
